<template>
    <div>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Earning Deductions</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Earning Deductions</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div class="row">
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <div class="col-lg-12 col-md-12">
                    <b-card-group deck >
                        <div class="col-lg-3 col-md-3 mb-3" v-if="hasPermission('CAN_MANAGE_PAYROLL_FORMULAS')">
                            <b-card bg-variant="dark" no-body text-variant="white" class="text-center">
                                <router-link :to="{name: 'payroll-formulas'}" style="color:#ffff;">
                                    <b-card-body>
                                        <b-card-text class="my-4">
                                            <h5>Payroll Formulas</h5>
                                        </b-card-text>
                                    </b-card-body>
                                </router-link>
                            </b-card>
                        </div>

                        <div class="col-lg-3 col-md-3 mb-3" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')">
                            <b-card bg-variant="dark" no-body text-variant="white" class="text-center">
                                <router-link :to="{name: 'default-earning-deductions'}" style="color:#ffff;">
                                    <b-card-body>
                                        <b-card-text class="my-4">
                                            <h5>Default Earning Deductions</h5>
                                        </b-card-text>
                                    </b-card-body>
                                </router-link>
                            </b-card>
                        </div>

                        <div class="col-lg-3 col-md-3 mb-3" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')">
                            <b-card bg-variant="dark" no-body text-variant="white" class="text-center">
                                <router-link :to="{name:'slab-earning-deductions'}" style="color:#ffff;">
                                    <b-card-body>
                                        <b-card-text class="my-4">
                                            <h5>Slab Earning Deductions</h5>
                                        </b-card-text>
                                    </b-card-body>
                                </router-link>
                            </b-card>
                        </div>

                        <div class="col-lg-3 col-md-3 mb-3" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')">
                            <b-card bg-variant="dark" no-body text-variant="white" class="text-center">
                                <router-link :to="{name: 'group-earning-deductions'}" style="color:#ffff;">
                                    <b-card-body>
                                        <b-card-text class="my-4">
                                            <h5>Group Earning Deductions</h5>
                                        </b-card-text>
                                    </b-card-body>
                                </router-link>
                            </b-card>
                        </div>

                        <div class="col-lg-3 col-md-3 mb-3" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')">
                            <b-card bg-variant="dark" no-body text-variant="white" class="text-center">
                                <router-link :to="{name: 'employee-earning-deductions'}" style="color:#ffff;">
                                    <b-card-body>
                                        <b-card-text class="my-4">
                                            <h5>Employee Earning Deductions</h5>
                                        </b-card-text>
                                    </b-card-body>
                                </router-link>
                            </b-card>
                        </div>

                        <div class="col-lg-3 col-md-3 mb-3" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')">
                            <b-card bg-variant="dark" no-body text-variant="white" class="text-center">
                                <router-link :to="{name: 'special-earnings'}" style="color:#ffff;">
                                    <b-card-body>
                                        <b-card-text class="my-4">
                                            <h5>Special Earning Deductions</h5>
                                        </b-card-text>
                                    </b-card-body>
                                </router-link>
                            </b-card>
                        </div>
                    </b-card-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            access: Boolean
        };
    },
};
</script>
